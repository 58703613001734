import { Box, Typography } from "@mui/material"
import { ReactElement } from "react"

import { useIsDesktop } from "@/utils/useIsDesktop"

interface DetailFieldProps {
  label: string
  children: ReactElement | string
  testId: string
}

export const DetailField = ({ label, children, testId }: DetailFieldProps) => {
  const isDesktop = useIsDesktop()

  return (
    <Box display="flex" minHeight={isDesktop ? 16 : 28} alignItems="center">
      <Typography variant="body2" color="text.secondary">
        {label}:
        <Typography
          component="span"
          variant="body2"
          color="text.primary"
          ml={0.5}
          data-testid={testId}
        >
          {children}
        </Typography>
      </Typography>
    </Box>
  )
}
