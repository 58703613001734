import { Box, Stack } from "@mui/material"
import { useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import {
  FeaturedInstruments,
  InstrumentsTable,
  TRADE_PAGE_LOCATORS,
} from "@/components"
import { SearchController } from "@/desktop/layout"
import { RootPath } from "@/desktop/paths"
import {
  setSearchValue,
  useSearchResult,
} from "@/services/instruments/searchInstruments"

import { InstrumentPage } from "../InstrumentsPage"

const SearchResults = () => {
  const instruments = useSearchResult()
  return (
    <InstrumentsTable
      instruments={instruments}
      data-testid={TRADE_PAGE_LOCATORS.search}
    />
  )
}

export const Trade = () => {
  const [isSearchActive, setIsSearchActive] = useState(false)

  return (
    <Stack direction="row" flex="1" gap={0.5}>
      <Box
        display="flex"
        flex="1 1 15%"
        minWidth={357}
        flexDirection="column"
        p={2}
        borderRadius={1}
        bgcolor="background.background0"
        sx={{
          height: (theme) => `calc(100vh - ${theme.spacing(8)})`,
          overflow: "scroll",
        }}
      >
        <SearchController
          setIsSearchActive={setIsSearchActive}
          isSearchActive={isSearchActive}
          setSearchValue={setSearchValue}
          searchResultComponent={<SearchResults />}
          data-testid={TRADE_PAGE_LOCATORS.search}
        >
          <Box p="2 2 2 2" gap={2}>
            <FeaturedInstruments />
          </Box>
        </SearchController>
      </Box>
      <Routes>
        <Route
          key="/"
          path="/"
          element={<Navigate to={RootPath.tradeInstrument("AAPL")} replace />}
        />
        <Route
          key="/instrument/:symbol"
          path="/instrument/:symbol"
          element={<InstrumentPage />}
        />
      </Routes>
    </Stack>
  )
}
