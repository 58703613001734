const BASE = "HeaderLocators"

export const HEADER_LOCATORS = {
  main: `${BASE}.main`,
  accountSummary: `${BASE}.accountSummary`,
  accountName: `${BASE}.accountName`,
  accountValue: `${BASE}.accountValue`,
  logo: `${BASE}.logo`,
  themeToggle: `${BASE}.themeToggle`,
}
