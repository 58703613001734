import { Box, Typography } from "@mui/material"

import { Icon, IconNames } from "@/components"

interface TabIconProps {
  icon: IconNames
  label: string
  isSelected: boolean
}

export const TabIcon = ({ icon, label, isSelected }: TabIconProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: isSelected ? "100%" : "50%",
        paddingInline: "8px",
        justifyContent: "center",
        backgroundColor: isSelected ? "background.background1" : "",
        boxShadow: isSelected ? 6 : 0,
      }}
    >
      <Icon name={icon} size="sm" color="text.primary" />
      <Typography
        variant="body2"
        sx={{
          color: "text.primary",
          textTransform: "uppercase",
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}
