import { Stack } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { t } from "i18next"
import { PropsWithChildren } from "react"

import { ErrorBoundary, Loading, SearchBar } from "@/components"

interface SearchControllerProps {
  searchResultComponent: React.ReactNode
  setSearchValue: (value: string) => void
  setIsSearchActive: (isSearchActive: boolean) => void
  isSearchActive: boolean
  "data-testid": string
}

export type Props = PropsWithChildren<SearchControllerProps>

export const SearchController = ({
  children,
  searchResultComponent,
  setSearchValue,
  setIsSearchActive,
  isSearchActive,
  "data-testid": testId,
}: Props) => {
  const activateSearch = () => {
    setIsSearchActive(true)
  }

  const deactivateSearch = () => {
    setIsSearchActive(false)
    setSearchValue?.("")
  }

  return (
    <>
      <SearchBar
        activateSearch={activateSearch}
        deactivateSearch={deactivateSearch}
        isActive={isSearchActive}
        data-testid={testId}
        size="sm"
        searchIcon="searchdark"
      />

      {isSearchActive ? (
        <ErrorBoundary fallback={<div>{t("errorLoadingInstruments")}</div>}>
          <Subscribe fallback={<Loading />}>{searchResultComponent}</Subscribe>
        </ErrorBoundary>
      ) : null}

      {/* hiding with css so that we don't have to reload the page when we navigate back */}
      <Stack display={isSearchActive ? "none" : undefined} gap={3}>
        {children}
      </Stack>
    </>
  )
}
