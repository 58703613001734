import { LinearProgress, Stack, Typography } from "@mui/material"
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid"
import { t } from "i18next"

import { InstrumentLogo } from "@/components"
import { useInstrument } from "@/services/instruments"
import { usePositionsValue } from "@/services/positions"
import { Position } from "@/services/TradingGateway"
import { formatFloat, formatPercentage, formatPercentageSigned } from "@/utils"

import { POSITIONS_PAGE_LOCATORS } from "./locators"

const floatFormatter = (params: GridValueFormatterParams<number>) =>
  params.value == null ? "" : formatFloat(params.value)

export const positionsGridColumns: GridColDef[] = [
  {
    field: "symbol",
    headerName: t("symbol"),
    width: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Position, string>) =>
      params.value ? (
        <SymbolCell
          symbol={params.value}
          data-testid={`${POSITIONS_PAGE_LOCATORS.grid}.row.${params.value}`}
        />
      ) : null,
  },
  {
    field: "portfolioPercent",
    headerName: t("portfolioPercent"),
    width: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Position>) => {
      return <PortfolioPercent value={params.row.market_value} />
    },
  },
  {
    field: "change_today",
    headerName: t("24hChange"),
    type: "number",
    width: 120,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value == null ? "" : formatPercentageSigned(params.value),
    cellClassName: (params: GridCellParams<Position, number>) =>
      !params.value || params.value == 0
        ? "none"
        : params.value > 0
        ? "positive"
        : "negative",
  },
  {
    field: "qty",
    headerName: t("quantity"),
    type: "number",
    sortable: false,
    valueFormatter: floatFormatter,
  },
  {
    field: "avg_entry_price",
    headerName: t("avgEntryPrice"),
    type: "number",
    width: 150,
    sortable: false,
    valueFormatter: floatFormatter,
  },
  {
    field: "market_value",
    headerName: t("valueInUsd"),
    type: "number",
    width: 150,
    sortable: false,
    valueFormatter: floatFormatter,
  },
  {
    field: "actions",
    type: "actions",
    sortable: false,
    getActions: () => [
      <GridActionsCellItem
        key="edit"
        label="Edit"
        onClick={() => {}}
        showInMenu
        data-testid="position.edit"
      />,
      <GridActionsCellItem
        key="close"
        label="Close"
        onClick={() => {}}
        showInMenu
        data-testid="position.close"
      />,
    ],
  },
]

interface SymbolCellProps {
  symbol: string
  "data-testid": string
}

const SymbolCell = ({ symbol, "data-testid": testId }: SymbolCellProps) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack direction="row" alignItems="center">
      <Stack pr={1.5}>
        <InstrumentLogo
          symbol={symbol}
          variant="md"
          data-testid={`${testId}.logo`}
        />
      </Stack>
      <Stack>
        <Typography variant="body1">{displayName}</Typography>
        <Typography variant="body2" color="text.secondary">
          {symbol}
        </Typography>
      </Stack>
    </Stack>
  )
}

const PortfolioPercent = ({ value }: { value: string }) => {
  const totalValue = usePositionsValue()
  const percent = parseFloat(value) / totalValue
  const percentFormatted = formatPercentage(percent)
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <LinearProgress
        variant="determinate"
        value={percent * 100}
        sx={({ palette }) => ({
          width: "60%",
          "& .MuiLinearProgress-bar": {
            backgroundColor: palette.positive!.main,
          },
        })}
      />
      <Stack>{percentFormatted}</Stack>
    </Stack>
  )
}
