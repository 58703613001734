import { t } from "i18next"

import { Instrument } from "@/services/TradingGateway"
import { formatCurrencyAbbreviated } from "@/utils/formatCurrency"

interface QuoteInfo {
  type: "quote"
  label: "bidPrice" | "askPrice"
}

interface InstrumentInfoInfo {
  type: "instrumentInfo"
  label: keyof Instrument
  formatter?: (input: string | number) => string
}

type InstrumentInfo = (QuoteInfo | InstrumentInfoInfo) & {
  field: string
  doubleWidth?: boolean
}

export const instrumentInfo: InstrumentInfo[] = [
  {
    field: "name",
    type: "instrumentInfo",
    label: t("instrument.name"),
    doubleWidth: true,
  },
  {
    field: "marketCapitalization",
    type: "instrumentInfo",
    label: t("instrument.marketCapitalization"),
    formatter: formatCurrencyAbbreviated,
  },
  {
    field: "exchange",
    type: "instrumentInfo",
    label: t("instrument.exchange"),
  },
  {
    field: "askPrice",
    type: "quote",
    label: t("instrument.askPrice"),
  },
  {
    field: "bidPrice",
    type: "quote",
    label: t("instrument.bidPrice"),
  },
  {
    field: "industry",
    type: "instrumentInfo",
    label: t("instrument.industry"),
    doubleWidth: true,
  },
  {
    field: "sector",
    type: "instrumentInfo",
    label: t("instrument.sector"),
    doubleWidth: true,
  },
]
