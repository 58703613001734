import { Box, Skeleton, TableCell, TableRow, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useNavigate } from "react-router-dom"

import {
  ChangeAmount,
  ChangePercentage,
  CurrentPriceValue,
  InstrumentLogo,
} from "@/components"
import { RootPath as RootPathDesktop } from "@/desktop/paths"
import { RootPath as RootPathMobile } from "@/mobile/paths"
import { Instrument } from "@/services/TradingGateway"
import { useIsDesktop } from "@/utils/useIsDesktop"

export const InstrumentRow = ({
  row,
  testId,
}: {
  row: Instrument
  testId: string
}) => {
  const navigate = useNavigate()
  const isDesktop = useIsDesktop()

  const rootPath = isDesktop ? RootPathDesktop : RootPathMobile
  return (
    <TableRow
      key={row.name}
      data-testid={testId}
      onClick={() => navigate(rootPath.tradeInstrument(row.symbol))}
      sx={{
        borderBottom: (theme) =>
          `solid ${theme.palette.background.background0}`,
        "&:hover": {
          background: ({ palette }) => palette.background.background1,
          cursor: "pointer",
        },
      }}
    >
      <TableCell>
        <Box
          display="flex"
          flexDirection="row"
          columnGap={2}
          alignItems="center"
          maxWidth={108}
        >
          <InstrumentLogo
            variant="md"
            symbol={row.symbol}
            data-testid={`logo`}
          />
          <Typography
            variant={isDesktop ? "body2" : "body1"}
            data-testid={`${testId}.name`}
          >
            {row.displayName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        <Subscribe fallback={<Skeleton width={50} />}>
          <Typography
            variant={isDesktop ? "body2" : "body1"}
            data-testid={`${testId}.lastPrice`}
          >
            <CurrentPriceValue symbol={row.symbol} />
          </Typography>
        </Subscribe>
      </TableCell>
      <TableCell align="right">
        <Subscribe fallback={<Skeleton width={50} />}>
          <ChangeAmount symbol={row.symbol} testId={`${testId}.priceChange`} />
        </Subscribe>
      </TableCell>
      <TableCell align="right">
        <Subscribe fallback={<Skeleton width={50} />}>
          <ChangePercentage
            symbol={row.symbol}
            testId={`${testId}.lastDayChange`}
          />
        </Subscribe>
      </TableCell>
    </TableRow>
  )
}
