import { useDailyBars } from "@/services/instruments/dailyBars"
import { RawDailyBar } from "@/services/TradingGateway"

export interface DailyBarValueProps {
  symbol: string
  field: keyof RawDailyBar
  formatter?: (input: string | number) => string
}

export const DailyBarValue = ({
  symbol,
  field,
  formatter,
}: DailyBarValueProps) => {
  const value = useDailyBars(symbol)[field]
  return formatter ? formatter(value) : value
}
