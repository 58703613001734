import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import { ReactNode, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { helloLogin } from "@/services/login/service"
import {
  setEmailLogged,
  setErrorInLogin,
  setIsEventBusNotReachable,
  setIsEventBusTimeout,
} from "@/services/login/signal"
import {
  useEmailLogged,
  useIsErrorInLogin,
  useIsEventBusNotReachable,
  useIsEventBusTimeout,
} from "@/services/login/state"
import { ErrorTypes } from "@/services/utils/errorTypes"
import { useIsDesktop } from "@/utils/useIsDesktop"

import { LOGIN_LOCATORS } from "./locators"

interface Props {
  buttonWrapper?: React.FC<{ children: ReactNode }>
}

const loginErrorMsg = (errorType: ErrorTypes) => {
  switch (errorType) {
    case ErrorTypes.INACTIVE_ACCOUNT:
      return t("inactiveUser")
    case ErrorTypes.SERVER_ERROR:
      return t("serverError")
    case ErrorTypes.UNKNOWN_ACCOUNT_KEY:
      return t("unknownAccountKey")
    default:
      return t("defaultError")
  }
}

export const LoginForm = ({ buttonWrapper }: Props) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const isErrorLogin = useIsErrorInLogin()
  const isEventBusTimeout = useIsEventBusTimeout()
  const isEventBusNotReachable = useIsEventBusNotReachable()
  const [loading, setLoading] = useState(false)
  const isDesktop = useIsDesktop()

  const emailLogged = useEmailLogged()
  const navigate = useNavigate()

  const signIn = () => {
    setErrorInLogin(null)
    setLoading(true)

    if (!import.meta.env.VITE_MOCKS) {
      helloLogin(email)
    } else if (!emailLogged) {
      setEmailLogged("mock")
      setErrorInLogin(null)
      setIsEventBusTimeout(false)
      setIsEventBusNotReachable(false)
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [isEventBusTimeout, isEventBusNotReachable, isErrorLogin, email])

  useEffect(() => {
    if (!isErrorLogin && emailLogged) {
      isDesktop ? navigate("/desktop/") : navigate("/mobile/")
    }
  }, [emailLogged, isErrorLogin, navigate, isDesktop])

  const ButtonWrapper = buttonWrapper ?? Stack

  return (
    <FormControl
      error={isEventBusTimeout || isEventBusNotReachable}
      sx={{ maxWidth: "350px", width: "350px", gap: 2 }}
    >
      <TextField
        label={t("email")}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        data-testid={LOGIN_LOCATORS.emailInput}
        error={Boolean(isErrorLogin)}
        helperText={isErrorLogin && loginErrorMsg(isErrorLogin)}
      />

      <TextField
        label={t("password")}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        data-testid={LOGIN_LOCATORS.passwordInput}
      />

      <FormControlLabel control={<Checkbox />} label={t("rememberMe")} />

      <Typography variant="body2">
        <Link href="#">{t("signup")}</Link> &nbsp;/&nbsp;{" "}
        <Link href="#">{t("forgottenPassword")}</Link>
      </Typography>

      <ButtonWrapper sx={{ paddingBlockStart: 1 }}>
        <Button
          data-testid={LOGIN_LOCATORS.loginButton}
          onClick={signIn}
          variant="contained"
          sx={{ color: "button.loginColor" }}
          disabled={loading || isEventBusNotReachable}
        >
          {t("login")}
          {loading && <CircularProgress size={24} />}
        </Button>
      </ButtonWrapper>
      <FormHelperText>
        {isEventBusTimeout
          ? t("eventBusTimeout")
          : isEventBusNotReachable && t("eventBusNotReachable")}
      </FormHelperText>
    </FormControl>
  )
}
