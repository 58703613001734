import "./utils/i18n"

import { CssBaseline } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { StrictMode } from "react"
import { BrowserRouter } from "react-router-dom"

import { ConnectionLost } from "./components/connectionLost/ConnectionLost"
import { ThemeProvider } from "./components/theme"
import { DesktopMobileSwitch } from "./DesktopMobileSwitch"
import { RegisterSW } from "./RegisterSW"
import { AppRoutes } from "./Routes"
import { Authenticated } from "./services/Authenticated"

export default function App() {
  return (
    <StrictMode>
      <RegisterSW />
      <ConnectionLost />
      <ThemeProvider>
        <CssBaseline />
        <BrowserRouter>
          <Subscribe fallback={<></>}>
            <Authenticated>
              <DesktopMobileSwitch />
              <AppRoutes />
            </Authenticated>
          </Subscribe>
        </BrowserRouter>
      </ThemeProvider>
    </StrictMode>
  )
}
