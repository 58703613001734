import { Stack, Typography } from "@mui/material"

import { InstrumentLogo } from "@/components"
import { useInstrument } from "@/services/instruments"

interface SymbolCellProps {
  symbol: string
  dataTestid: string
}

export const SymbolCell = ({ symbol, dataTestid }: SymbolCellProps) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack direction="row" alignItems="center">
      <Stack pr={1.5}>
        <InstrumentLogo
          symbol={symbol}
          variant="md"
          data-testid={`${dataTestid}.logo`}
        />
      </Stack>
      <Stack>
        <Typography variant="body1">{displayName}</Typography>
        <Typography variant="body2" color="text.secondary">
          {symbol}
        </Typography>
      </Stack>
    </Stack>
  )
}
