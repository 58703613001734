import { Stack } from "@mui/material"

import { Logo, ThemeToggle } from "@/components"

import { AccountSummary } from "./AccountSummary"
import { Navigation } from "./Navigation"

export const Header = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      minHeight={({ constants }) => constants.desktopHeaderHeight}
      bgcolor="background.background0"
      borderRadius={1}
      px={3.5}
    >
      <Stack flexBasis={1}>
        <Logo />
      </Stack>
      <Stack flexBasis={1} flexGrow={1} alignItems="center">
        <Navigation />
      </Stack>
      <Stack flexBasis={1} direction="row" alignItems="center" gap={2}>
        <ThemeToggle />
        <AccountSummary />
      </Stack>
    </Stack>
  )
}
