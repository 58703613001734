import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import { ErrorBoundary, Loading } from "@/components"
import { SimpleViewLayout } from "@/mobile/layout"
import { RootPath } from "@/mobile/paths"
import { usePositions } from "@/services/positions"

import { List } from ".."
import { MOBILE_POSITIONS_PAGE_LOCATORS } from "./locators"
import { PositionListItem } from "./PositionListItem"
import { PositionsHeader } from "./PositionsHeader"

export const Positions = () => {
  const { t } = useTranslation()
  return (
    <SimpleViewLayout
      data-testid=""
      routePattern={RootPath.positions}
      title={<PositionsHeader />}
    >
      <ErrorBoundary fallback={<div>{t("errorLoadingPositions")}</div>}>
        <Subscribe fallback={<Loading />}>
          <PositionsList />
        </Subscribe>
      </ErrorBoundary>
    </SimpleViewLayout>
  )
}

const PositionsList = () => {
  const positions = usePositions()

  return (
    <List data={positions} keyField="symbol">
      {(position, index) => (
        <PositionListItem
          {...position}
          data-testid={`${MOBILE_POSITIONS_PAGE_LOCATORS.main}.row.${index}`}
        />
      )}
    </List>
  )
}
