import { Box, Skeleton, Stack } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useEffect } from "react"

import {
  LimitOrderForm,
  MarketOrderForm,
  OrderErrorAlert,
  StopOrderForm,
  SubmitOrderButton,
  TradeButton,
} from "@/components"
import { BalanceDetails } from "@/components/order/BalanceDetails"
import { OrderTabs } from "@/components/order/OrderTabs"
import {
  Direction,
  reset,
  setShowTradeReceipt,
  toggleDirection,
  useResponse,
  useShowTradeReceipt,
  useTradeDirection,
} from "@/services/orders"
import { OrderStatus } from "@/services/TradingGateway/OrderService"

import { OrderReceipt } from "./OrderReceipt"

export const OrderContainer = () => {
  const { isLoading, response } = useResponse()
  const showTradeReceipt = useShowTradeReceipt()

  useEffect(() => {
    if (!isLoading && response) {
      if (response.type === OrderStatus.accepted) {
        reset()
        setShowTradeReceipt(true)
      }
    }
  }, [isLoading, response])

  return (
    <Stack
      flex="1 1 15%"
      minWidth={323}
      p={({ spacing }) => spacing(2)}
      borderRadius={1}
      bgcolor="background.background0"
      sx={{
        height: (theme) => `calc(100vh - ${theme.spacing(8)})`,
        overflow: "scroll",
      }}
    >
      <Box display="flex" flexDirection="column" flex={1}>
        {showTradeReceipt ? <OrderReceipt /> : <OrderForm />}
      </Box>
    </Stack>
  )
}

const OrderForm = () => {
  const tradeDirection = useTradeDirection()

  return (
    <>
      <Box display="flex" gap={2} padding={2}>
        <Subscribe fallback={<Skeleton />}>
          <TradeButton
            disableElevation
            direction={Direction.Buy}
            sx={{
              height: 36,
            }}
            onClick={() => toggleDirection(Direction.Buy)}
          />
          <TradeButton
            disableElevation
            direction={Direction.Sell}
            sx={{
              height: 36,
            }}
            onClick={() => toggleDirection(Direction.Sell)}
          />
        </Subscribe>
      </Box>
      <OrderTabs
        limitOrderForm={<LimitOrderForm />}
        marketOrderForm={<MarketOrderForm />}
        stopOrderForm={<StopOrderForm />}
      />
      <SubmitOrderButton disableElevation direction={tradeDirection} />
      <BalanceDetails />
      <OrderErrorAlert />
    </>
  )
}
