import { Box, Skeleton, Stack } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { setQty, useQtyValue } from "@/services/orders"
import { useIsDesktop } from "@/utils/useIsDesktop"

import { IndicativeCost } from "../../TradeValues"
import { DetailField } from "../DetailField"
import { OrderInput } from "../OrderInputs"

export const MarketOrderForm = () => {
  const { t } = useTranslation()
  const { symbol = "" } = useParams()

  const qtyValue = useQtyValue()
  const isDesktop = useIsDesktop()

  return (
    <Box gap={1} display="flex" flexDirection="column" pt={2}>
      <Box display="flex" position="relative" gap={0.5}>
        <Subscribe fallback={<Skeleton />}>
          <Stack flex={1}>
            <OrderInput
              onChange={(value) => setQty(value)}
              data-testid="marketQty"
              value={qtyValue}
              name="marketQty"
              label={t("quantity")}
            />
          </Stack>
        </Subscribe>
      </Box>
      <Box paddingTop={2} paddingBottom={isDesktop ? 2 : 0}>
        <Subscribe fallback={<Skeleton />}>
          <DetailField
            label={t("orderIndicativeCost")}
            testId="orderIndicativeCost"
          >
            <IndicativeCost symbol={symbol} />
          </DetailField>
        </Subscribe>
      </Box>
    </Box>
  )
}
