import { useAccountSummary } from "@/services/accountSummary"
import { useIndicativeCostValue } from "@/services/orders"
import { formatCurrency } from "@/utils"

interface IndicativeCostProps {
  symbol: string
}

export const IndicativeCost = ({ symbol }: IndicativeCostProps) => {
  const indicativeCost: string = useIndicativeCostValue(symbol)

  return formatCurrency(indicativeCost)
}

export const PortfolioValue = () => {
  return formatCurrency(useAccountSummary().portfolio_value)
}

export const CashValue = () => {
  return formatCurrency(useAccountSummary().cash)
}
