import { Divider, Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import { CurrentPriceValue, InstrumentLogo, PortfolioValue } from "@/components"
import { TradeDetail } from "@/components/order/TradeDetail"
import { useInstrument } from "@/services/instruments"
import { Order } from "@/services/TradingGateway"
import { formatDate } from "@/utils/formatDate"

const InstrumentNameAndLogo = ({ symbol }: { symbol: string }) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack direction="row" columnGap={1} alignItems="center" my={1}>
      <InstrumentLogo
        variant="md"
        symbol={symbol}
        data-testid={`tradeInformation.logo`}
      />
      <Stack>
        <Typography variant="h2" data-testid={`tradeInformation.displayName`}>
          <Subscribe fallback={<Skeleton />}>{displayName}</Subscribe>
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          data-testid={`tradeInformation.symbol`}
        >
          {symbol}
        </Typography>
      </Stack>
    </Stack>
  )
}

interface TradeReceiptInformationProps {
  symbol: string
  orderResponse: Order
}

export const TradeReceiptInformation = ({
  symbol,
  orderResponse,
}: TradeReceiptInformationProps) => {
  const { t } = useTranslation()

  if (!orderResponse) {
    throw Error()
  }

  return (
    <Stack>
      <Typography my={2.35} variant="h2">
        {t("tradeInformation.tradeReceipt")}
      </Typography>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{
          borderBottomWidth: 2,
          borderColor: "background.divider",
        }}
      />
      <InstrumentNameAndLogo symbol={symbol} />
      <Stack gap={1}>
        <TradeDetail
          label={t("tradeInformation.orderQty")}
          value={orderResponse.order.qty}
          testId="tradeInformation.orderQty"
        />
        <TradeDetail
          label={t("tradeInformation.unitPrice")}
          value={<CurrentPriceValue symbol={symbol} />}
          testId="tradeInformation.unitPrice"
        />
        <TradeDetail
          label={t("tradeInformation.currentPosition")}
          value={<PortfolioValue />}
          testId="tradeInformation.currentPosition"
        />
        <TradeDetail
          label={t("tradeInformation.timeStamp")}
          value={formatDate(orderResponse.order.createdAt)}
          testId="tradeInformation.timeStamp"
        />
        <TradeDetail
          label={t("tradeInformation.orderRef")}
          value={orderResponse.order.clientOrderId}
          testId="tradeInformation.orderRef"
        />
      </Stack>
    </Stack>
  )
}
