import { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { useIsDesktop } from "./utils/useIsDesktop"
import { useIsLoginPage } from "./utils/useIsLoginPage"

export const DesktopMobileSwitch = () => {
  const isDesktop = useIsDesktop()
  const isLoginPage = useIsLoginPage()
  const location = useLocation().pathname
  const navigate = useNavigate()

  useEffect(() => {
    if (!isDesktop && (location.includes("/desktop/") || location === "/")) {
      navigate("/mobile/")
    } else if (
      isDesktop &&
      (location.includes("/mobile/") || location === "/")
    ) {
      navigate("/desktop/")
    } else if (!isDesktop && isLoginPage) {
      navigate("/mobile/login")
    }
  }, [location, isDesktop, isLoginPage, navigate])

  return <Outlet />
}
