import { Navigate, Route, Routes } from "react-router-dom"

import { instruments$ } from "@/services/instruments"
import { withSubscribe } from "@/utils/withSubscribe"

import { OrderHistory } from "./components/orderHistory"
import { Positions } from "./components/positions"
import { Trade } from "./components/trade"
import { Navigation } from "./components/utils"
import { RootPath } from "./paths"

export const MobileRoutes = withSubscribe(
  () => {
    return (
      <>
        <Routes>
          <Route
            key="/"
            path="/*"
            element={<Navigate to={RootPath.trade} replace />}
          />
          <Route key="/trade" path="/trade/*" element={<Trade />} />
          <Route key="/positions" path="/positions/*" element={<Positions />} />
          <Route
            key="/orderhistory"
            path="/orderhistory/*"
            element={<OrderHistory />}
          />
        </Routes>
        <Navigation />
      </>
    )
  },
  {
    source$: instruments$,
  },
)
