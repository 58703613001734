import { catchError, map, Observable, of } from "rxjs"
import { ajax } from "rxjs/ajax"

import { Timeframe } from "@/components/chart/types"
import { constructQuery } from "@/utils/constructQuery"

import { RawHistoricalBar } from "../TradingGateway/InstrumentsService"

export enum RestEndPoint {
  getHistoricalBar = "stocks/{symbol}/bars?timeframe={timeframe}&endDate={endDate}",
}

type Request = {
  [RestEndPoint.getHistoricalBar]: {
    symbol: string
    timeframe: Timeframe
    endDate?: string
  }
}

type Response = {
  [RestEndPoint.getHistoricalBar]: RawHistoricalBar[]
}

export type RestService<E extends RestEndPoint> = Request[E] extends undefined
  ? () => Observable<Response[E]>
  : (args: Request[E]) => Observable<Response[E]>

export function createRESTService$<E extends RestEndPoint>(
  endpoint: E,
): RestService<E> {
  return (args?: Request[E]) =>
    ajax<E>(`/api/${constructQuery(endpoint, args)}`).pipe(
      map((r) => r.response),
      catchError((error) => {
        console.error("error: ", error)
        return of(error)
      }),
    )
}
