import { bind } from "@react-rxjs/core"
import { createSignal, mergeWithKey } from "@react-rxjs/utils"
import { CIQ } from "chartiq/js/advanced"
import { merge, scan, tap, withLatestFrom } from "rxjs"

import { filterNotNull } from "@/utils"

import { quoteFeed } from "./quotefeed"
import { timeframeConfig } from "./timeframe"
import { Timeframe } from "./types"

const [_currentSymbol$, setCurrentSymbol] = createSignal<string | undefined>()
const [useCurrentSymbol, currentSymbol$] = bind(
  _currentSymbol$.pipe(filterNotNull()),
)

export { setCurrentSymbol, useCurrentSymbol }

const [newChartEngine$, setChartEngine] = createSignal<CIQ.ChartEngine>()
const [clearChartEngine$, clearChartEngine] = createSignal()

export { clearChartEngine, setChartEngine }

const [useChartEngine, _chartEngine$] = bind(
  mergeWithKey({
    newChartEngine$,
    clearChartEngine$,
  }).pipe(
    scan((prevEngine: CIQ.ChartEngine | null, action) => {
      if (prevEngine) {
        prevEngine.detachQuoteFeed(quoteFeed)
        const chartContainer = prevEngine.container as HTMLDivElement
        chartContainer.innerHTML = ""
        prevEngine.destroy()
      }

      if (action.type === "newChartEngine$") {
        const engine = action.payload
        engine.attachQuoteFeed(quoteFeed, { refreshInterval: 1 })

        return engine
      }

      return null
    }, null),
  ),
  null,
)
const chartEngine$ = _chartEngine$.pipe(filterNotNull())

export { useChartEngine }

const [_timeframe$, setTimeframe] = createSignal<Timeframe>()
export const [useTimeframe, timeframe$] = bind(_timeframe$, "10m")

export { setTimeframe }

const loadChartData$ = chartEngine$.pipe(
  withLatestFrom(currentSymbol$, timeframe$),
  tap(([chartEngine, symbol, timeframe]) =>
    chartEngine.loadChart(symbol, {
      periodicity: timeframeConfig[timeframe],
    }),
  ),
)

const changeTimeframe$ = timeframe$.pipe(
  withLatestFrom(chartEngine$),
  tap(([timeframe, chartEngine]) => {
    chartEngine.setPeriodicity(timeframeConfig[timeframe])
  }),
)

export const chartTriggers$ = merge(loadChartData$, changeTimeframe$)
