import { Box, styled, Tab, Tabs } from "@mui/material"
import { ReactNode, SyntheticEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { OrderType, toggleOrderType } from "@/services/orders"
import { useIsDesktop } from "@/utils/useIsDesktop"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <Box display="flex" flexDirection="column" paddingTop={2} gap={1.5}>
      <Box role="tabpanel" hidden={value !== index}>
        {children}
      </Box>
    </Box>
  )
}

interface OrdertabsProps {
  limitOrderForm: ReactNode
  stopOrderForm: ReactNode
  marketOrderForm: ReactNode
}

const orderStyles = {
  Desktop: {
    minHeight: 42,
    fontSize: 12,
    minWidth: 65,
  },
  mobile: {
    minHeight: 64,
    fontSize: 20,
    minWidth: 75,
  },
}

const OrderTab = styled(Tab)(({ theme }) => {
  const isDesktop = useIsDesktop()

  const styles = isDesktop ? orderStyles["Desktop"] : orderStyles["mobile"]

  return {
    ...theme.typography.h3,
    textTransform: "capitalize",
    fontSize: styles.fontSize,
    minHeight: styles.minHeight,
    minWidth: styles.minWidth,
  }
})

export const OrderTabs = ({
  limitOrderForm,
  marketOrderForm,
  stopOrderForm,
}: OrdertabsProps) => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (_: SyntheticEvent, index: number) => {
    switch (index) {
      case 0:
        toggleOrderType(OrderType.MarketOrder)
        break
      case 1:
        toggleOrderType(OrderType.LimitOrder)
        break
      case 2:
        toggleOrderType(OrderType.StopOrder)
        break
    }

    setTabIndex(index)
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          sx={{
            minHeight: (isDesktop
              ? orderStyles["Desktop"]
              : orderStyles["mobile"]
            ).minHeight,
          }}
          centered={!isDesktop}
        >
          <OrderTab label={t("market")} />
          <OrderTab label={t("limit")} />
          <OrderTab label={t("stop")} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabIndex} index={0}>
        {marketOrderForm}
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={1}>
        {limitOrderForm}
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={2}>
        {stopOrderForm}
      </CustomTabPanel>
    </>
  )
}
