import "chartiq/css/stx-chart.css"
import "./chart.css"
import "./chartiqKey"

import { Box } from "@mui/material"
import { CIQ } from "chartiq/js/standard"

import { useIsDesktop } from "@/utils/useIsDesktop"
import { withSubscribe } from "@/utils/withSubscribe"

import { chartTriggers$ } from "./state"
import { useRegisterChartEngine } from "./useRegisterChartEngine"

window.getLicenseKey(CIQ)

const ChartContainer = () => {
  const container = useRegisterChartEngine()
  const isDesktop = useIsDesktop()
  const height = isDesktop ? "500px" : "300px"

  return (
    <Box
      sx={{ position: "relative", height: height }}
      p={({ spacing }) => spacing(1, 4)}
    >
      <Box ref={container} className="chartContainer" sx={{ height: "100%" }} />
    </Box>
  )
}

export const Chart = withSubscribe(() => <ChartContainer />, {
  source$: chartTriggers$,
})
