import { bind } from "@react-rxjs/core"
import { distinctUntilChanged, map } from "rxjs"

import { Instrument, InstrumentsService } from "@/services/TradingGateway"
import { INSTRUMENTS_COUNT } from "@/utils/constants"

import { WSEndPoint } from "../utils/registerWSService$"
import { scanPagingEvents } from "../utils/scanPagingEvents"

export const featuredInstrumentSymbols = [
  "AAPL",
  "AMZN",
  "F",
  "GE",
  "META",
  "MSFT",
  "NFLX",
  "NVDA",
  "SPY",
  "TSLA",
]

export const [useInstruments, instruments$] = bind<Instrument[]>(
  InstrumentsService.getInstruments().pipe(
    scanPagingEvents<Instrument>(WSEndPoint.getInstruments),
    distinctUntilChanged(),
  ),
)

export const [useInstrument, useInstrument$] = bind((symbol: string) =>
  instruments$.pipe(
    map((instruments) =>
      instruments.find((instrument) => instrument.symbol === symbol),
    ),
  ),
)

export const [useFeaturedInstruments] = bind(
  instruments$.pipe(
    map((instruments) => {
      const featured = instruments.filter(({ symbol }) =>
        featuredInstrumentSymbols.includes(symbol),
      )

      // Fill up with miscellaneous instruments
      if (featured.length < INSTRUMENTS_COUNT) {
        featured.push(
          ...instruments
            .filter(({ symbol }) => !featuredInstrumentSymbols.includes(symbol))
            .slice(0, INSTRUMENTS_COUNT - featured.length),
        )
      }

      return featured
    }),
  ),
)
