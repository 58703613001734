import { Box, Skeleton } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import {
  setLimitPrice,
  setLimitTimeInForce,
  setQty,
  useLimitPriceValue,
  useQtyValue,
  useTimeInForceValue,
} from "@/services/orders"
import { useIsDesktop } from "@/utils/useIsDesktop"

import { IndicativeCost } from "../../TradeValues"
import { DetailField } from "../DetailField"
import { OrderInput, OrderSelectBox } from "../OrderInputs"
import { timeInForceSelections } from "./timeInForceSelections"

export const LimitOrderForm = () => {
  const { t } = useTranslation()
  const { symbol = "" } = useParams()

  const limitPriceValue = useLimitPriceValue()
  const limitQtyValue = useQtyValue()
  const limitTimeInForceValue = useTimeInForceValue()
  const isDesktop = useIsDesktop()

  return (
    <Box gap={2} pt={2} display="flex" flexDirection="column">
      <Subscribe fallback={<Skeleton />}>
        <OrderInput
          onChange={(value) => setQty(value)}
          value={limitQtyValue}
          data-testid="limitQty"
          name="limitQty"
          label={t("quantity")}
        />
        <OrderInput
          onChange={(value) => setLimitPrice(value)}
          value={limitPriceValue}
          data-testid="limitPrice"
          name="limitPrice"
          label={t("limitPrice")}
        />
        <OrderSelectBox
          options={timeInForceSelections}
          onChange={(e) => setLimitTimeInForce(e.target.value as string)}
          value={limitTimeInForceValue}
          data-testid="timeinForce"
          name="timeInForce"
          label={t("timeInForce")}
        />
      </Subscribe>
      <Box gap={2} paddingTop={2} paddingBottom={isDesktop ? 2 : 0}>
        <Subscribe fallback={<Skeleton />}>
          <DetailField
            label={t("orderIndicativeCost")}
            testId="orderIndicativeCost"
          >
            <IndicativeCost symbol={symbol} />
          </DetailField>
        </Subscribe>
      </Box>
    </Box>
  )
}
