import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { useLatestQuote } from "@/services/instruments"
import { formatCurrency, useChangePaletteColor } from "@/utils"

export type QuoteDataPointField = "askPrice" | "bidPrice"

interface QuoteDataPointProps {
  symbol: string
  field: QuoteDataPointField
}

export const QuoteDataPoint = ({ symbol, field }: QuoteDataPointProps) => {
  const { marketOpen, [field]: price } = useLatestQuote(symbol)
  const color = useChangePaletteColor({
    current: price,
    fallback: "primary",
  })

  const { t } = useTranslation()

  return marketOpen ? (
    <Typography component="span" variant="inherit" color={color}>
      {formatCurrency(price)}
    </Typography>
  ) : (
    <>{t("marketClosed")}</>
  )
}
