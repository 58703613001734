import { DataGrid } from "@mui/x-data-grid"

import { useOrderHistory } from "@/services/orderHistory"
import { OrderHistory } from "@/services/TradingGateway"

import { orderHistoryGridColumns } from "./OrderHistoryGridColumns"

export const OrderHistoryGrid = () => {
  const data = useOrderHistory()

  return (
    <DataGrid<OrderHistory>
      sx={{ mx: -4, px: 4, pt: 2.5 }}
      rows={data}
      columns={orderHistoryGridColumns}
      getRowId={(order) => order.clientOrderId}
    />
  )
}
