import { getNextPage } from "./nextPage"
import {
  CREATED_PAGINATION_EVENT,
  DELETED_PAGINATION_EVENT,
  END_OF_PAGE_PAGINATION_EVENT,
  END_OF_SNAPSHOT_PAGINATION_EVENT,
  PaginationEvent,
  SNAPSHOT_PAGINATION_EVENT,
  START_OF_SNAPSHOT_PAGINATION_EVENT,
  UPDATED_PAGINATION_EVENT,
  WSEndPoint,
} from "./registerWSService$"

const getItemKey = <T extends { symbol: string; clientOrderId?: string }>(
  item: T,
): string => {
  return item?.clientOrderId || item.symbol
}

export const reducePagingEvents = <T extends { symbol: string }>(
  state: { endOfSnapshot: boolean; items: T[] },
  event: PaginationEvent<T>,
  endPoint: WSEndPoint,
) => {
  switch (event.type) {
    case START_OF_SNAPSHOT_PAGINATION_EVENT: {
      getNextPage({ endPoint })
      return { endOfSnapshot: false, items: [] }
    }

    case END_OF_SNAPSHOT_PAGINATION_EVENT:
      return { ...state, endOfSnapshot: true }

    case SNAPSHOT_PAGINATION_EVENT: {
      const itemMap = Object.fromEntries(
        state.items.map((item) => [getItemKey(item), item]),
      )

      return {
        ...state,
        items: Object.values({
          ...itemMap,
          [getItemKey(event.item)]: event.item,
        }),
      }
    }

    case END_OF_PAGE_PAGINATION_EVENT: {
      getNextPage({
        endPoint,
        continuationToken: event.continuationToken,
      })
      return state
    }

    case CREATED_PAGINATION_EVENT:
      return { ...state, items: [...state.items, event.item] }

    case UPDATED_PAGINATION_EVENT: {
      if (state.endOfSnapshot) {
        const instrumentMap = Object.fromEntries(
          state.items.map((item) => [getItemKey(item), item]),
        )

        return {
          ...state,
          items: Object.values({
            ...instrumentMap,
            [getItemKey(event.item)]: event.item,
          }),
        }
      }

      return state
    }

    case DELETED_PAGINATION_EVENT: {
      const itemMap = Object.fromEntries(
        state.items.map((item) => [item.symbol, item]),
      )

      const { [event.symbol]: deletedItem, ...items } = itemMap

      // eslint-disable-next-line no-console
      console.log("Deleted item:", deletedItem.symbol)

      return {
        ...state,
        items: Object.values(items),
      }
    }

    default:
      throw Error(`Did not recognise service event type: ${event}`)
  }
}
