import { Snackbar } from "@mui/material"
import { useEffect, useState } from "react"

import { useResponse } from "@/services/orders"
import { OrderStatus } from "@/services/TradingGateway/OrderService"
import { AUTO_HIDE_DURATION } from "@/utils/constants"

export const OrderErrorAlert = () => {
  const { isLoading, response } = useResponse()

  const [showToaster, setShowToaster] = useState(false)

  useEffect(() => {
    if (!isLoading && response) {
      if (response.type !== OrderStatus.accepted) {
        setShowToaster(true)
      }
    }
  }, [isLoading, response])

  const handleCloseToaster = () => {
    setShowToaster(false)
  }

  return (
    <Snackbar
      sx={{
        ".MuiSnackbarContent-root": {
          justifyContent: "center",
          color: "white",
          backgroundColor: ({ palette }) => palette.error.main,
        },
      }}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={showToaster}
      onClose={handleCloseToaster}
      message={response?.error?.message || response?.error?.msg?.message || ""}
    />
  )
}
