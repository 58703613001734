import { bind } from "@react-rxjs/core"
import { map } from "rxjs"

import { InstrumentsService } from "../TradingGateway"
import { useLatestQuote } from "."

export const [useDailyBars, dailyBars$] = bind((symbol: string) =>
  InstrumentsService.getDailyBars({ symbol }),
)

export type PriceAndChange = {
  price: number
  change: number
  timestamp: string
  changeAmount: number
}

export const [usePriceAndChange, priceAndChange$] = bind<
  [string],
  PriceAndChange
>((symbol: string) => {
  const { askPrice, bidPrice } = useLatestQuote(symbol)
  return dailyBars$(symbol).pipe(
    map(({ c, o, t }) => {
      const marketOpen = (askPrice || bidPrice || 0) > 0
      const changeAmount = marketOpen ? askPrice! - c : c - o
      const change = marketOpen ? changeAmount / c : changeAmount / o
      const price = marketOpen ? askPrice! : c

      return {
        price,
        change,
        timestamp: t,
        changeAmount,
      }
    }),
  )
})
