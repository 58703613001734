const ARG_STRING = /^([a-zA-Z]+)=\{([a-zA-Z]+)\}$/

export const constructQuery = (
  query: string,
  args?: Record<string, string>,
): string => {
  if (!args) {
    return query
  }

  // Replace {arg} with arg value
  const queryWithValue = query.replace(/{([a-zA-Z]+)}/g, (match, key) => {
    return args[key] ?? match
  })

  // Remove any arg that has not been replaced in previous step
  const [mainQuery, argsString] = queryWithValue.split("?")
  const cleanedArgsString = argsString
    .split("&")
    .filter((arg) => !ARG_STRING.test(arg))
    .join("&")

  return `${mainQuery}${cleanedArgsString ? "?" : ""}${cleanedArgsString}`
}
