import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"

import {
  instrumentInfo,
  InstrumentInfoDataPoint,
  QuoteDataPoint,
} from "@/components"
import { QuoteDataPointField } from "@/components/instruments/QuoteDataPoint"
import { InfoField } from "@/mobile/components/InfoField"
import { Instrument } from "@/services/TradingGateway"

interface Props {
  symbol: string
}

export const InstrumentDetails = ({ symbol }: Props) => {
  return (
    <Box padding={({ spacing }) => spacing(0, 4, 0, 4)}>
      <Grid container gap={3} direction="column">
        {instrumentInfo.map((info) => {
          const { label, type, doubleWidth, field } = info

          return (
            <InfoField label={label} key={field} doubleWidth={doubleWidth}>
              {type === "quote" && (
                <QuoteDataPoint
                  field={field as QuoteDataPointField}
                  symbol={symbol}
                />
              )}

              {type === "instrumentInfo" && (
                <InstrumentInfoDataPoint
                  field={field as keyof Instrument}
                  symbol={symbol}
                  formatter={info.formatter}
                />
              )}
            </InfoField>
          )
        })}
      </Grid>
    </Box>
  )
}
