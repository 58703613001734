import { IconButton, Input, InputAdornment, Theme } from "@mui/material"
import { ChangeEventHandler, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { useIsDesktop } from "@/utils/useIsDesktop"

import {
  setSearchValue,
  useSearchValue,
} from "../services/instruments/searchInstruments"
import { Icon } from "./Icon"

interface SearchBarProps {
  activateSearch: () => void
  deactivateSearch: () => void
  isActive: boolean
  "data-testid": string
  size?: "xs" | "sm" | "md" | "lg"
  searchIcon?: "search" | "searchdark"
}

const SearchBarButton = ({
  isActive,
  deactivateSearch,
  activateSearch,
  "data-testid": testId,
  size = "lg",
  searchIcon = "search",
}: SearchBarProps) => (
  <InputAdornment position="end" data-testid={`${testId}.button`}>
    <IconButton
      onClick={() => (isActive ? deactivateSearch() : activateSearch())}
    >
      <Icon
        color="action.active"
        name={isActive ? "close" : searchIcon}
        size={size}
      />
    </IconButton>
  </InputAdornment>
)

export const SearchBar = (props: SearchBarProps) => {
  const value = useSearchValue()
  const { t } = useTranslation()
  const input = useRef<HTMLInputElement>(null)
  const isDesktop = useIsDesktop()

  const { activateSearch, deactivateSearch, isActive } = props

  useEffect(() => {
    if (isActive) {
      input.current?.focus()
    }
  }, [isActive])

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value)
  }

  const handleFocus = () => {
    activateSearch()
  }

  const handleBlur = () => {
    if (value === "") {
      deactivateSearch()
    }
  }

  const fontSize = isDesktop ? "12px" : "20px"
  const height = isDesktop ? "16px" : "24px"

  const inputProps = {
    sx: {
      fontWeight: "normal",
      fontSize,
      borderRadius: "32px",
      backgroundColor: (theme: Theme) =>
        `${theme.palette.background.background10}16`,
      ".MuiInput-input": {
        padding: 2,
        height,
      },
      width: "100%",
    },
    disableUnderline: true,
  }

  return (
    <Input
      name="searchBar"
      value={value}
      placeholder={t("search")}
      startAdornment={<SearchBarButton {...props} />}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputRef={input}
      size="medium"
      {...inputProps}
    />
  )
}
