import { switchMap } from "rxjs"

import { historicalBars$ } from "@/services/instruments/historicalBars"

import { timeframe$ } from "./state"
import { ChartIQQuoteFeed } from "./types"

export const quoteFeed: ChartIQQuoteFeed = {
  fetchInitialData(symbol, _startDate, _endDate, _params, cb) {
    timeframe$
      .pipe(switchMap((timeframe) => historicalBars$(symbol, timeframe)))
      .subscribe({
        next: (historicalBars) => cb({ quotes: historicalBars }),
        error: (res) => cb({ error: res }),
      })
  },
  fetchPaginationData(symbol, startDate, endDate, _params, cb) {
    timeframe$
      .pipe(
        switchMap((timeframe) =>
          historicalBars$(symbol, timeframe, endDate.toISOString()),
        ),
      )
      .subscribe({
        next: (historicalBars) =>
          // get 5 years max of data
          cb({
            quotes: historicalBars,
            moreAvailable:
              startDate.getTime() > Date.now() - 5 * 365 * 24 * 60 * 60 * 1_000,
          }),
        error: (res) => cb({ error: res }),
      })
  },
}
