import { DataGrid } from "@mui/x-data-grid"

import { usePositions } from "@/services/positions"
import { Position } from "@/services/TradingGateway"

import { positionsGridColumns } from "./PositionsGridColumns"

export const PositionsGrid = () => {
  const positions = usePositions()

  return (
    <DataGrid
      sx={{ mx: -4, px: 4, pt: 2.5 }}
      rows={positions}
      columns={positionsGridColumns}
      getRowId={(position: Position) => position.symbol}
    />
  )
}
