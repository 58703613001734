import { bind } from "@react-rxjs/core"

import {
  emailLogged$,
  isErrorInLogin$,
  isEventBusConnected$,
  isEventBusNotReachable$,
  isEventBusTimeout$,
} from "./signal"

export const [useEmailLogged] = bind(emailLogged$)
export const [useIsErrorInLogin] = bind(isErrorInLogin$)
export const [useIsEventBusConnected] = bind(isEventBusConnected$)
export const [useIsEventBusTimeout] = bind(isEventBusTimeout$)
export const [useIsEventBusNotReachable] = bind(isEventBusNotReachable$)
