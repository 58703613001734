import { PropsWithChildren, useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useIsDesktop } from "@/utils/useIsDesktop"
import { useIsLoginPage } from "@/utils/useIsLoginPage"

import { eventBus } from "./connection"
import { helloLogin } from "./login/service"
import {
  setEmailLogged,
  setIsEventBusConnected,
  setIsEventBusNotReachable,
} from "./login/signal"
import {
  useEmailLogged,
  useIsEventBusConnected,
  useIsEventBusNotReachable,
  useIsEventBusTimeout,
} from "./login/state"

export const Authenticated = ({ children }: PropsWithChildren) => {
  const eventBusIsConnected = useIsEventBusConnected()

  const navigate = useNavigate()
  const isLoginPage = useIsLoginPage()
  const isDesktop = useIsDesktop()
  const emailLogged = useEmailLogged()
  const emailDataStore = localStorage.getItem("email")
  const isEventBusTimeout = useIsEventBusTimeout()
  const isEventBusNotReachable = useIsEventBusNotReachable()

  const handleEventBusClose = useCallback(() => {
    setIsEventBusNotReachable(true)
    setEmailLogged(null)
    localStorage.removeItem("email")
    if (!isLoginPage) {
      navigate(0)
    }
  }, [navigate, isLoginPage])

  const handleEventBusOpen = useCallback(() => {
    setIsEventBusConnected(true)
  }, [])

  if (!import.meta.env.VITE_MOCKS) {
    eventBus.onclose = () => {
      handleEventBusClose
    }
  }

  useEffect(() => {
    if (!import.meta.env.VITE_MOCKS) {
      if (!eventBusIsConnected || isEventBusNotReachable) {
        eventBus.onopen = function () {
          handleEventBusOpen()
        }
      } else if (emailDataStore !== null && !emailLogged) {
        helloLogin(emailDataStore)
      } else if (emailLogged === null) {
        isDesktop ? navigate("/desktop/login") : navigate("/mobile/login")
      }
      if (isEventBusTimeout) {
        setEmailLogged(null)
      }
    } else {
      if (emailLogged === null) {
        isDesktop ? navigate("/desktop/login") : navigate("/mobile/login")
      }
    }
  }, [
    navigate,
    emailLogged,
    emailDataStore,
    eventBusIsConnected,
    isEventBusNotReachable,
    isEventBusTimeout,
    isDesktop,
    handleEventBusOpen,
  ])

  return children
}
