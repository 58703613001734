import { ThemeProvider as MuiThemeProvider } from "@mui/material"
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"

import { themes } from "@/theme"

const STORAGE_KEY = "themeMode"

export enum ThemeMode {
  DARK = "dark",
  LIGHT = "light",
}

interface ContextValue {
  themeMode: ThemeMode
  setThemeMode: Dispatch<SetStateAction<ThemeMode>>
}

export const ThemeContext = createContext<ContextValue>({
  themeMode: ThemeMode.DARK,
  // eslint-disable-next-line no-console
  setThemeMode: () => console.warn("Missing StorageThemeProvider"),
})

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(
    (localStorage.getItem(STORAGE_KEY) ?? ThemeMode.DARK) as ThemeMode,
  )

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, themeMode)
  }, [themeMode])

  return (
    <ThemeContext.Provider value={{ themeMode, setThemeMode }}>
      <MuiThemeProvider theme={themes[themeMode]}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const { themeMode, setThemeMode } = useContext(ThemeContext)
  const toggleTheme = () =>
    setThemeMode((prevThemeMode) =>
      prevThemeMode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK,
    )

  return { theme: themes[themeMode], themeMode, toggleTheme }
}
