import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import { InstrumentLogo } from "@/components"
import { useInstrument } from "@/services/instruments"
import { Position } from "@/services/TradingGateway"
import { formatCurrency, getChangeTextColor } from "@/utils"
import { formatProfitAndLoss } from "@/utils/formatCurrency"

interface Props extends Position {
  "data-testid": string
}

export const PositionListItem = ({
  symbol,
  side,
  qty,
  unrealized_pl,
  current_price,
  lastday_price,
  "data-testid": testId,
}: Props) => {
  const { t } = useTranslation()

  const pl = Number(unrealized_pl)
  const direction = side === "long" ? t("buy") : t("sell")

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1.5}
      padding={1}
      margin={-1}
      data-testid={testId}
    >
      <InstrumentLogo symbol={symbol} data-testid={`${testId}.logo`} />

      <Stack flexGrow={1} gap={0.5} overflow="hidden">
        <Stack direction="row" gap={1.5} alignItems="baseline">
          <Stack flexGrow={1} overflow="hidden">
            <Typography
              variant="body1"
              color="text.primary" // TODO: this should be replaced by a link style coming from a MuiLink
              noWrap
              data-testid={`${testId}.name`}
            >
              <Subscribe fallback={<Skeleton />}>
                <InstrumentName symbol={symbol} />
              </Subscribe>
            </Typography>
          </Stack>
          <Typography
            variant="body1"
            color="text.primary" // TODO: this should be replaced by a link style coming from a MuiLink
            data-testid={`${testId}.price`}
          >
            {formatCurrency(current_price ?? lastday_price)}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1.5} alignItems="baseline">
          <Typography
            flexGrow={1}
            variant="body1"
            color="text.secondary"
            data-testid={`${testId}.directionAndQty`}
          >
            {`${direction} ${qty}`}
          </Typography>
          <Typography
            variant="body1"
            color={getChangeTextColor({ change: pl })}
            data-testid={`${testId}.pl`}
          >
            {formatProfitAndLoss(unrealized_pl)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

const InstrumentName = ({ symbol }: { symbol: string }) => {
  const displayName = useInstrument(symbol)?.displayName
  return displayName
}
