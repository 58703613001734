import { Theme } from "@emotion/react"
import { Button, ButtonProps, SxProps, Typography } from "@mui/material"
import { t } from "i18next"

import { directionToButtonColor } from "@/components"
import { Direction } from "@/services/orders"

import { INSTRUMENT_PAGE_LOCATORS } from "../../instruments"

const DIRECTION_TRANSLATIONS = {
  [Direction.Buy]: t("tradeButton.buy"),
  [Direction.Sell]: t("tradeButton.sell"),
}

interface TradeButtonProps extends ButtonProps {
  direction: Direction
  sx?: SxProps<Theme>
  to?: string
}

export const TradeButton = ({ direction, sx, ...props }: TradeButtonProps) => {
  return (
    <Button
      data-testid={INSTRUMENT_PAGE_LOCATORS[direction]}
      color={directionToButtonColor(direction)}
      sx={{
        flex: 1,
        borderRadius: 2,
        ...sx,
      }}
      {...props}
    >
      <Typography variant="h4" textTransform="uppercase">
        {DIRECTION_TRANSLATIONS[direction]}
      </Typography>
    </Button>
  )
}
