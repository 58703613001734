import { Box, Skeleton } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { IndicativeCost } from "@/components"
import {
  setLimitPrice,
  setLimitTimeInForce,
  setQty,
  setStopPrice,
  useLimitPriceValue,
  useQtyValue,
  useStopPriceValue,
  useTimeInForceValue,
} from "@/services/orders"
import { useIsDesktop } from "@/utils/useIsDesktop"

import { DetailField } from "../DetailField"
import { OrderInput, OrderSelectBox } from "../OrderInputs"
import { timeInForceSelections } from "./timeInForceSelections"

export const StopOrderForm = () => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const { symbol = "" } = useParams()

  const stopPriceValue = useStopPriceValue()
  const qtyValue = useQtyValue()
  const timeInForceValue = useTimeInForceValue()
  const limitPriceValue = useLimitPriceValue()

  return (
    <Box gap={1} display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        gap={isDesktop ? 1 : 2}
      >
        <Subscribe fallback={<Skeleton />}>
          <OrderInput
            onChange={(value) => setQty(value)}
            value={qtyValue}
            name="marketQty"
            label={t("quantity")}
          />
          <OrderInput
            onChange={(value) => setStopPrice(value)}
            value={stopPriceValue}
            name="stopPrice"
            label={t("stopPrice")}
          />
          <OrderInput
            onChange={(value) => setLimitPrice(value)}
            value={limitPriceValue}
            name="limitPrice"
            label={t("limitPrice")}
          />
          <OrderSelectBox
            options={timeInForceSelections}
            onChange={(e) => setLimitTimeInForce(e.target.value as string)}
            value={timeInForceValue}
            name="timeInForce"
            label={t("timeInForce")}
          />
        </Subscribe>
      </Box>
      <Box>
        <Subscribe fallback={<Skeleton />}>
          <DetailField
            label={t("orderIndicativeCost")}
            testId="orderIndicativeCost"
          >
            <IndicativeCost symbol={symbol} />
          </DetailField>
        </Subscribe>
      </Box>
    </Box>
  )
}
