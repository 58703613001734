import { bind } from "@react-rxjs/core"
import { map, Observable } from "rxjs"

import { Timeframe } from "@/components/chart/types"

import { InstrumentsService } from "../TradingGateway"

// Using PascalCase here as ChartIQ specifically expects the data to be in this format
export interface HistoricalBar {
  Date: string
  Open: number
  High: number
  Low: number
  Close: number
  Volume: number
}

export const [useHistoricalBars, historicalBars$] = bind(
  (
    symbol: string,
    timeframe: Timeframe,
    endDate?: string,
  ): Observable<HistoricalBar[]> =>
    InstrumentsService.getHistoricalBars({
      symbol,
      timeframe,
      endDate,
    }).pipe(
      map((rawHistoricalBars) =>
        rawHistoricalBars.map(({ o, h, l, c, v, t }) => ({
          Date: t,
          Open: o,
          High: h,
          Low: l,
          Close: c,
          Volume: v,
        })),
      ),
    ),
)
