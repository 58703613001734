import { filter, map, Observable, scan } from "rxjs"

import { reducePagingEvents } from "./reducePagingEvents"
import { PaginationEvent, WSEndPoint } from "./registerWSService$"

export const scanPagingEvents =
  <T extends { symbol: string }>(endPoint: WSEndPoint) =>
  (obs: Observable<PaginationEvent<T>>) =>
    obs.pipe(
      scan<PaginationEvent<T>, { endOfSnapshot: boolean; items: T[] }>(
        (state, items) => reducePagingEvents(state, items, endPoint),
        {
          endOfSnapshot: true,
          items: [],
        },
      ),
      filter(({ endOfSnapshot }) => endOfSnapshot),
      map(({ endOfSnapshot, items }) => (endOfSnapshot ? items : [])),
    )
