import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { ErrorBoundary, INSTRUMENT_PAGE_LOCATORS } from "@/components"

import { OrderContainer } from "../Order/OrderContainer"
import { InstrumentChart } from "./InstrumentChart"
import { InstrumentDetails } from "./InstrumentDetails"
import { InstrumentHeader } from "./InstrumentHeader"
import { ToggleChartHeaderGroup } from "./ToggleChartHeaderGroup"

export const InstrumentPage = () => {
  const { t } = useTranslation()

  const { symbol } = useParams()
  if (!symbol) {
    throw Error()
  }

  return (
    <>
      <Box
        flex="1 1 70%"
        minWidth={752}
        borderRadius={1}
        bgcolor="background.background0"
        sx={{
          height: (theme) => `calc(100vh - ${theme.spacing(8)})`,
          overflow: "scroll",
        }}
        data-testid="InstrumentPage"
      >
        <InstrumentHeader
          symbol={symbol}
          data-testid={INSTRUMENT_PAGE_LOCATORS.root}
        />
        <ToggleChartHeaderGroup />
        <ErrorBoundary
          fallback={<div>{t("errorLoadingInstrumentDetails")}</div>}
        >
          <InstrumentChart symbol={symbol} />
          <InstrumentDetails symbol={symbol} />
        </ErrorBoundary>
      </Box>
      <OrderContainer />
    </>
  )
}
