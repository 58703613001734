import { createSignal } from "@react-rxjs/utils"
import { shareReplay, startWith } from "rxjs"

import { ErrorTypes } from "../utils/errorTypes"

const [emailLogged$, setEmailLogged] = createSignal<string | null>()

const emailLoggedWithDefault$ = emailLogged$.pipe(
  startWith(null),
  shareReplay(1),
)

const [isErrorInLogin$, setErrorInLogin] = createSignal<ErrorTypes | null>()

const isErrorInLoginWithDefault$ = isErrorInLogin$.pipe(
  startWith(null),
  shareReplay(1),
)

const [isEventBusConnected$, setIsEventBusConnected] = createSignal<boolean>()

const isEventBusConnectedWithDefault$ = isEventBusConnected$.pipe(
  startWith(false),
  shareReplay(1),
)

const [isEventBusTimeout$, setIsEventBusTimeout] = createSignal<boolean>()

const isEventBusTimeoutWithDefault$ = isEventBusTimeout$.pipe(
  startWith(false),
  shareReplay(1),
)

const [isEventBusNotReachable$, setIsEventBusNotReachable] =
  createSignal<boolean>()

const isEventBusNotReachableWithDefault$ = isEventBusNotReachable$.pipe(
  startWith(false),
  shareReplay(1),
)

export {
  emailLoggedWithDefault$ as emailLogged$,
  isErrorInLoginWithDefault$ as isErrorInLogin$,
  isEventBusConnectedWithDefault$ as isEventBusConnected$,
  isEventBusNotReachableWithDefault$ as isEventBusNotReachable$,
  isEventBusTimeoutWithDefault$ as isEventBusTimeout$,
  setEmailLogged,
  setErrorInLogin,
  setIsEventBusConnected,
  setIsEventBusNotReachable,
  setIsEventBusTimeout,
}
