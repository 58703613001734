import { Box, Link as MuiLink, Stack } from "@mui/material"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { Icon } from "@/components"

import { TOP_BAR_LOCATORS } from "./locators"

export interface TopBarProps {
  back?: {
    to: string
    title?: string
  }
  trailing?: ReactNode
}

export const TopBar = ({ back, trailing }: TopBarProps) => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" justifyContent="space-between">
      {back ? (
        <MuiLink
          to={back.to}
          component={Link}
          variant="body2"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            textDecoration: "none",
            color: "text.primary",
          }}
          data-testid={TOP_BAR_LOCATORS.back}
        >
          <Icon component="span" name="chevronleft" size="sm" />{" "}
          {back.title ?? t("back")}
        </MuiLink>
      ) : (
        <Box aria-hidden>&nbsp;</Box>
      )}

      {trailing ? <Box>{trailing}</Box> : null}
    </Stack>
  )
}
