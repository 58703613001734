import { useTranslation } from "react-i18next"

import { ErrorBoundary } from "@/components/ErrorBoundary"
import { useFeaturedInstruments } from "@/services/instruments"
import { withSubscribe } from "@/utils/withSubscribe"

import { Loading } from "../Loading"
import { InstrumentsTable } from "./InstrumentsTable"
import { TRADE_PAGE_LOCATORS } from "./locators"

const FeaturedInstrumentsTable = () => {
  const instruments = useFeaturedInstruments()
  return (
    <InstrumentsTable
      instruments={instruments}
      data-testid={TRADE_PAGE_LOCATORS.featured}
    />
  )
}

export const FeaturedInstruments = withSubscribe(
  () => {
    const { t } = useTranslation()
    return (
      <ErrorBoundary fallback={<div>{t("errorLoadingInstruments")}</div>}>
        <FeaturedInstrumentsTable />
      </ErrorBoundary>
    )
  },
  { fallback: <Loading /> },
)
