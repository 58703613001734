import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { t } from "i18next"
import { useTranslation } from "react-i18next"

import { CurrentPriceValue, InstrumentLogo, PortfolioValue } from "@/components"
import { TradeDetail } from "@/components/order/TradeDetail"
import { useInstrument } from "@/services/instruments"
import { Order } from "@/services/TradingGateway"
import { formatDate } from "@/utils/formatDate"

import { TRADE_RECEIPT_LOCATORS } from "./locators"

interface HeaderProps {
  orderResponse: Order
}

const Header = ({ orderResponse }: HeaderProps) => {
  const { t } = useTranslation()

  if (!orderResponse) {
    throw Error()
  }

  return (
    <Stack gap={1}>
      <Typography variant="h1" data-testid="tradeInformation.tradeReceipt">
        {t("tradeInformation.tradeReceipt")}
      </Typography>
      <Typography variant="h2" data-testid="tradeInformation.date">
        {formatDate(orderResponse.order.createdAt)}
      </Typography>
      <Typography variant="h2" data-testid="tradeInformation.orderRefDynamic">
        {t("tradeInformation.orderRefDynamic", {
          ref: orderResponse.order.clientOrderId,
        })}
      </Typography>
    </Stack>
  )
}

const InstrumentNameAndLogo = ({ symbol }: { symbol: string }) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        variant="h1"
        sx={{ flexGrow: 1 }}
        data-testid={TRADE_RECEIPT_LOCATORS.displayName}
      >
        <Subscribe fallback={<Skeleton />}>{displayName}</Subscribe>
      </Typography>
      <InstrumentLogo
        symbol={symbol}
        data-testid={TRADE_RECEIPT_LOCATORS.logo}
      />
    </Stack>
  )
}

interface TradeReceiptInformationProps {
  symbol: string
  orderResponse: Order
}

export const TradeReceiptInformation = ({
  symbol,
  orderResponse,
}: TradeReceiptInformationProps) => {
  if (!orderResponse) {
    throw Error()
  }

  return (
    <Stack p={3} gap={4}>
      <Header orderResponse={orderResponse} />
      <InstrumentNameAndLogo symbol={symbol} />
      <Stack gap={1}>
        <TradeDetail
          label={t("tradeInformation.orderQty")}
          direction="row"
          value={orderResponse.order.qty}
          testId="tradeInformation.orderQty"
        />
        <TradeDetail
          label={t("tradeInformation.unitPrice")}
          direction="row"
          value={<CurrentPriceValue symbol={symbol} />}
          testId="tradeInformation.unitPrice"
        />
      </Stack>
      <TradeDetail
        label={t("tradeInformation.currentPosition")}
        value={<PortfolioValue />}
        testId="tradeInformation.currentPosition"
      />
    </Stack>
  )
}
